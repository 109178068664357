import React, { useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Logo from 'reusecore/src/elements/UI/Logo';
import Box from 'reusecore/src/elements/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';

import LogoImage from 'common/src/assets/image/logo-mario-markovic-alt.png';
import LogoImageAlt from 'common/src/assets/image/logo-mario-markovic.png';

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper, className }) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className={`portfolio_navbar ${className}`}>
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Mario Markovic"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Mario Markovic"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}
            className="main_menu_wrapper">
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.portfolioJson.MENU_ITEMS}
              offset={-70}
            />
            <Link className="navbar_button navbar_button_back_to_home" to="/">
              <Button {...button} title="BACK TO HOME" />
            </Link>
            <Link className="navbar_button" to="/lets-talk">
              <Button {...button} title="LET'S TALK" />
            </Link>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#3444f1" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
              className={`${className}`}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.portfolioJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              <Link className="navbar_button navbar_button_back_to_home" to="/">
                <Button {...button} title="BACK TO HOME" />
              </Link>
              <Link className="navbar_drawer_button" to="/lets-talk">
                <Button {...button} title="LET'S TALK" />
              </Link>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['90px', '109px'],
  },
  button: {
    type: 'button',
    fontSize: ['14px', '16px'],
    pl: '0',
    pr: '0',
    colors: 'primary',
    minHeight: 'auto',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
