import styled from 'styled-components';

export const ClientsImage = styled.div`
  position: relative;
  padding: 20px 28px;
  flex-shrink: 0;

  @media (max-width: 991px) {
    padding: 15px 10px;
    zoom: 0.8;
  }

  @media (max-width: 575px) {
    padding: 15px 12px;
    zoom: 0.8;
  }

@media (max-width: 320px) {
  zoom: 0.7;
}

  &:hover {
    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  img {
    filter: grayscale(1);
    opacity: 0.5;
    transition: all 0.3s ease-in-out 0s;
  }
`;
