import styled from 'styled-components';

const BannerWrapper = styled.section`
  position: relative;
  background-color: #030b16;
  display: flex;
  align-items: center;
  padding-top: 80px;
  display: flex;
  align-items: center;
  @media (min-width: 991px) {
    min-height: 700px;
  }

  .image_area {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
  }
`;

export default BannerWrapper;
