import styled from 'styled-components';
import {
  display,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  boxShadow,
  color,
  space,
  borderRadius,
  width,
  height
} from 'styled-system';

const NavbarStyle = styled.nav`
  /* Navbar default style goes here */
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 10px 16px;
  
  /* Style system supported prop */
  ${display}
  ${alignItems}
  ${justifyContent}
  ${flexDirection}
  ${flexWrap}
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}

  &.nav-static {
    .main_menu li a {
      color: #302b4e;

      &:hover {
        color: #302b4e;
      }

      &:after {
        background: #c2c7fb;
      }
    }

    .mobile_menu {
      @media (max-width: 991px) {
        display: none;
      }
    }

    .main-logo {
      display: none;
    }

    .logo-alt {
      display: block;
    }

    .navbar_button_back_to_home {
      display: block !important;
    }

    .main_menu {
      display: none;
    }
  }

  &.nav-centered {
    .main_menu_wrapper {
      display: none;
    }

    > div {
      > div {
        justify-content: center;
      }
    }
  }

  .main_menu_wrapper {
    .navbar_button_back_to_home {
      @media (max-width: 991px) {
        display: none !important;
      }
    }
  }
`;

NavbarStyle.displayName = 'NavbarStyle';

export default NavbarStyle;
